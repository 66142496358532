<template>
    <div>
        <div id="evGmap" class="col-12 border border-primary"></div>
    </div>
</template>

<script type="module" src="@/assets/js/fundPlane.js"></script>
<script type="module" src="@/assets/js/xephem.js"></script>

<script>
import { FundPlane } from '@/assets/js/fundPlane.js'
import gmapsInit from '@/assets/js/gmaps';
import { xephem } from '@/assets/js/xephem.js'

export default {
    name: 'Map',
    props: ['location'],
    data() {
        return {
            map: null,
            occData : null,
            fPlane: null,
            midTime: null,
            travelDist: 1000,
            autoZoom: true,
            currentLocation: null,
            gMapRenderAreaChangedThrottledTimer: 0,
            poliLines: null,
            debugMode: false,
            tFrom: 0,
            tTo: 0,
            xephem: null,
            twilightLines: [null, null, null]
        }
    },   
    async mounted() {
        this.debugMode = process.env.NODE_ENV != 'production';
        this.xephem = new xephem();

        this.poliLines = new Map();
        try 
        {
            const google = await gmapsInit();
            this.map = this.initNightViewMap();           
            this.map.mapTypeId = localStorage.getItem("mapTypeId") ?? "hybrid";

            this.map.addListener("bounds_changed", this.onGMapRenderAreaChanged);

            this.currentLocation = new google.maps.LatLng(this.location.Lat, this.location.Lng);

            let zoomLevel = 6;

            if (this.currentLocation)
            {
               this.map.setCenter(this.currentLocation, 6);
               this.map.setZoom(zoomLevel);
            }

            if (this.occData)
                this.plotSelectedEvent();
        }
        catch (error) 
        {
            console.error(error);
        }
    },
    methods: {
        updateLocation(loc){
            if (this.map)
            {
                this.currentLocation = new google.maps.LatLng(loc.Lat, loc.Lng);
                this.map.setCenter(this.currentLocation);
            }
        },
        plotEvent(fPlane, mt, travelDist, autoZoom){

            this.midTime = mt;
            this.travelDist = travelDist;
            this.autoZoom = autoZoom;
            this.fPlane = fPlane;
            this.occData = new FundPlane(fPlane);
            
            this.plotSelectedEvent();
        },
        plotSelectedEvent()
        {
            if (this.map)
            {
                this.plotTwilightLines();

                if (this.autoZoom)
                {
                    var matchingZoom = this.getMatchingZoomLevel(this.travelDist, this.fPlane.asteroidDiameterAugmented);
                    this.map.setCenter(this.currentLocation, matchingZoom);
                    this.map.setZoom(matchingZoom);
                }

                this.onGMapRenderAreaChanged();
            }
        },
        getMatchingZoomLevel(travelDistance, diameterAugm)
        {
            let distance = travelDistance + diameterAugm;
            if (distance <= 5) return 12;
            else if (distance <= 10) return 11;
            else if (distance <= 30) return 10;
            else if (distance <= 50) return 9;
            else if (distance <= 50) return 9;
            else if (distance <= 125) return 8;
            else if (distance <= 250) return 7;
            else if (distance <= 500) return 6;
            else if (distance <= 1000) return 5;
            return 3;
        },
        formatLatLngString(val) {
            let sign = val < 0 ? '-' : '+';
            let value = Math.abs(val);
            var deg = Math.floor(value);
            var minP = (value - deg) * 60;
            var min = Math.floor(minP);
            var secP = (minP - min) * 60;
            var sec = Math.floor(secP);

            deg = (deg + '').padStart(2, '0');
            min = (min + '').padStart(2, '0');
            let sec_whole = (sec + '').padStart(2, '0');
            return `${sign}${deg}&deg; ${min}<sup>&apos;</sup> ${sec_whole}<sup>&quot;</sup>`;
        },
        formatTimeUtc(dateTime){
            return `${(dateTime.getHours() + '').padStart(2, '0')}:${(dateTime.getMinutes() + '').padStart(2, '0')}:${(dateTime.getSeconds() + '').padStart(2, '0')} UT`;
        },
        formatAltAzString(alt, az) {
            if (!alt)
                return "";

            if (!az)
                return `${alt.toFixed()}&deg;`;
            
            let azDir = '';
            if (az <= 0 + 22.5 || az > 360 - 22.5)
                azDir = 'N';
            else if (az <= 45 * 1 + 22.5 && az > 45 * 1 - 22.5)
                azDir = 'NE';
            else if (az <= 45 * 2 + 22.5 && az > 45 * 2 - 22.5)
                azDir = 'E';
            else if (az <= 45 * 3 + 22.5 && az > 45 * 3 - 22.5)
                azDir = 'SE';
            else if (az <= 45 * 4 + 22.5 && az > 45 * 4 - 22.5)
                azDir = 'S';
            else if (az <= 45 * 5 + 22.5 && az > 45 * 5 - 22.5)
                azDir = 'SW';
            else if (az <= 45 * 6 + 22.5 && az > 45 * 6 - 22.5)
                azDir = 'W';
            else if (az <= 45 * 7 + 22.5 && az > 45 * 7 - 22.5)
                azDir = 'NW';

            return `${alt.toFixed()}&deg; ${azDir}`;         
        },
        onGMapRenderAreaChanged() {
            clearTimeout(this.gMapRenderAreaChangedThrottledTimer);
            this.gMapRenderAreaChangedThrottledTimer = setTimeout(this.onGMapRenderAreaChangedThrottled, 150);
        },
        onGMapRenderAreaChangedThrottled() {
            if (!this.occData) return;

            let t0 = performance.now();
            this.tFrom = 0;
            this.tTo = 0;

            let hasNaNTaEdge = this.occData.tFrom.some(x => x == NaN || x == "NaN") || this.occData.tTo.some(x => x == NaN || x == "NaN");  

            let zoom = this.map.getZoom();
            
            if (this.debugMode)
                console.log(`Zoom = ${zoom}`);            

            if (zoom >= 6 || hasNaNTaEdge)
            {                
                var bnds = this.map.getBounds();
                var ne = bnds.getNorthEast();
                var sw = bnds.getSouthWest();            
                let lngFrom = ne.lng();
                let lngTo = sw.lng();
                let latFrom = ne.lat();
                let latTo = sw.lat();
                let tBnds = [0, 0, 0, 0];
                tBnds[0] = this.occData.getTforMinimumDistance(lngFrom, latFrom, null);
                tBnds[1] = this.occData.getTforMinimumDistance(lngFrom,latTo, null);
                tBnds[2] = this.occData.getTforMinimumDistance(lngTo, latFrom, null);
                tBnds[3] = this.occData.getTforMinimumDistance(lngTo, latTo, null);
                this.tFrom = Math.min(...tBnds.map(x => x.ta));
                this.tTo = Math.max(...tBnds.map(x => x.ta));

                this.drawPathLineFromTo(this.tFrom, this.tTo, -2, "#ff0000");
                this.drawPathLineFromTo(this.tFrom, this.tTo, -1, "#0000ff");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 0, "#00ff00");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 1, "#0000ff");
                this.drawPathLineFromTo(this.tFrom, this.tTo, 2, "#ff0000");
            }
            else
            {
                if (this.debugMode)
                    console.log('Drawing full path for zoom < 6');

                this.drawPathLine(-2, "#ff0000");
                this.drawPathLine(-1, "#0000ff");
                this.drawPathLine(0, "#00ff00");
                this.drawPathLine(1, "#0000ff");
                this.drawPathLine(2, "#ff0000");        
            }


            let t1 = performance.now();
            if (this.debugMode)
                console.log(`Lines redrawn in:`+ (t1-t0) +' milliseconds');          
        },
        drawPathLine(limitId, colour) {
            let from = this.occData.tFrom[-1 * limitId + 2];
            let to = this.occData.tTo[-1 * limitId + 2];  
            this.drawPathLineFromTo(from, to, limitId, colour);
        },
        drawPathLineFromTo(from, to, limitId, colour) {
            if (this.debugMode)
                console.log(`drawPathLineFromTo(from: ${from}, to: ${to}, limitId: ${limitId}, colour: ${colour})`);   

            const CHUNKS = 1000;
            let poliLinesKey = limitId;

            let tick = (to - from) / (CHUNKS + 1);
            var linePoints = [ ];
            let dp = { };
            for (let i = 0; i <= CHUNKS; i++)
            {
                let ta = from + i * tick;

                dp = this.occData.centrePathByTime(ta, limitId);

                if (dp.valid)
                    linePoints.push(new google.maps.LatLng(dp.latDeg, dp.lngDeg));
            }

            if (this.poliLines.has(poliLinesKey))
                this.poliLines.get(poliLinesKey).setMap(null);

            let line = new google.maps.Polyline({
                path: linePoints,
                strokeColor: colour,
                strokeWeight: 2,
                clickable: false,
                geodesic: false,
                map: this.map
            });

            this.poliLines.set(poliLinesKey, line);
            
        },
        plotTwilightLines() {
            let t0 = performance.now();

            let rdSun = this.xephem.getSunPosition(this.midTime);            
            let jd = this.xephem.JD_from_DateString(this.midTime);
            let sidTim = this.xephem.siderealTimeDeg(jd);
            let lat0 = -rdSun.dec;
            let long0 = 180 + rdSun.ra - sidTim;
            long0 = this.xephem.range(long0, 360);            
            for(var sunAltStep = 0; sunAltStep < 3; sunAltStep++)
            {
                let subAlt = 0 - 6 * sunAltStep;
                var linePoints = new Array(360);
                
                for(var i = -90; i<=90; i++)
                {
                    let step = 0.5;
                    if (Math.abs(i) >= 60) step = 0.25;
                    if (Math.abs(i) >= 70) step = 0.1;
                    if (Math.abs(i) >= 80) step = 0.02;
                    let lat = i;
                    while(lat < i + 1)
                    {
                        let lngRv = this.xephem.longitudeForAltitudeAndLatitude(sidTim, subAlt, lat, rdSun.ra / 15, rdSun.dec);
                        if (!isNaN(lngRv.lng1))
                        {
                            linePoints[i + 90] = new google.maps.LatLng(lat, lngRv.lng1);
                            linePoints[(90-i) + 180] = new google.maps.LatLng(lat, lngRv.lng2);
                        }
                        lat += step;
                    }
                }

                var termColours = ["#ffff00", "#623999", "#2e006c"];

                linePoints = linePoints.filter(x => x);
                linePoints.push(linePoints[0]);

                if (this.twilightLines && this.twilightLines[sunAltStep]) this.twilightLines[sunAltStep].setMap(null);

                this.twilightLines[sunAltStep] = new google.maps.Polyline({
                    path: linePoints,
                    strokeColor: termColours[sunAltStep],
                    strokeWeight: 2,
                    clickable: false,
                    geodesic: false,
                    map: this.map
                });                
            }
            let t1 = performance.now();
            if (this.debugMode)
                console.log(`Twilight lines drawn in:`+ (t1-t0) +' milliseconds');          
        },
        CenterMapAtPosition(lat, lng){
            this.map.setCenter(new google.maps.LatLng(lat, lng));
        },      
        getZoomLevelForDistance(distance) {
            let distanceAbs = Math.abs(distance);
            if (this.debugMode) console.log('distanceAbs', distanceAbs);
            if (distanceAbs < 25)
                return 9;
            else if (distanceAbs < 100)
                return 8;
            else if (distanceAbs < 250)
                return 7;
            else if (distanceAbs < 500)
                return 6;
            else if (distanceAbs < 1000)
                return 5; 
            
            return 4;
        },
        initNightViewMap(){
            if (!this.location) return;
            var mapStyles = null;
            var isLightTheme = localStorage.getItem('theme') === 'light';
            if (!isLightTheme)
            {
                mapStyles = [
                    { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
                    { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
                    {
                        featureType: "administrative.locality",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "geometry",
                        stylers: [{ color: "#263c3f" }],
                    },
                    {
                        featureType: "poi.park",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#6b9a76" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry",
                        stylers: [{ color: "#38414e" }],
                    },
                    {
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#212a37" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#9ca5b3" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry",
                        stylers: [{ color: "#746855" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.stroke",
                        stylers: [{ color: "#1f2835" }],
                    },
                    {
                        featureType: "road.highway",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#f3d19c" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry",
                        stylers: [{ color: "#2f3948" }],
                    },
                    {
                        featureType: "transit.station",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#d59563" }],
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{ color: "#17263c" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.fill",
                        stylers: [{ color: "#515c6d" }],
                    },
                    {
                        featureType: "water",
                        elementType: "labels.text.stroke",
                        stylers: [{ color: "#17263c" }],
                    },
                ];
            }

            var map = new google.maps.Map(document.getElementById('evGmap'), 
            {
                disableDefaultUI: true,
                keyboardShortcuts: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                zoom: 3,
                styles: mapStyles
            });

            return map;
        }
    },
    computed: {
        isTagged() {
            return this.tags.length > 0;
        }
    }
}
</script>

<style scoped>
#evGmap {
    height:570px;
    width: 100%;
   }

.delete-btn {
    padding: 0.5em 1em;
    background-color: #eccfc9;
    color: #c5391a;
    border: 2px solid #ea3f1b;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}
</style>