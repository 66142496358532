<template>
    <div />
</template>

<script>
export default {
    async mounted() {
        var data = JSON.parse(localStorage.getItem("editStationsData"));
        console.log('Redirecting to edit stations for prediction', data);
        this.$router.push({ name: 'Prediction', params: { eventId: data.eventId, predSrc: data.predictionId }});
    }
}
</script>
