<template>
    <nav class="nav navbar-expand-sm" id="top-nav">
        <router-link  class="navbar-brand mx-4 pb-1" to="/">OW Cloud</router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#mynav">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse pb-1 mx-3" id="mynav">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <router-link class="nav-link" to="/">Home</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/events">Events</router-link>
                </li>
                <li v-if="isLoggedInUserFeedMaintainer" class="nav-item">
                    <router-link class="nav-link" to="/tags">Tags</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/campaigns">Campaigns</router-link>
                </li>                
                <li class="nav-item">
                    <router-link class="nav-link" to="/filters">Filters</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/search">Search</router-link>
                </li>                
                <li v-if="tagEventsVisible && isLoggedInUserFeedMaintainer" class="nav-item">
                    <router-link class="nav-link" to="/tag-events">Tag Events</router-link>
                </li>
                <li v-if="isLoggedInUserFeedMaintainer" class="nav-item">
                    <router-link class="nav-link" to="/upload-events">Upload Events</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link" to="/about">About</router-link>
                </li>                
            </ul>
            <ThemeSelector />
            <LoggedInUserActions :viewPortVisible="viewPortVisible"/>        
        </div>
    </nav>    
</template>

<script>
    import LoggedInUserActions from '@/components/LoggedInUserActions.vue'
    import ThemeSelector from '@/components/ThemeSelector.vue'

    export default {
        data(){
            return {
                tagEventsVisible: (process.env.NODE_ENV != 'production'),
                viewPortVisible: (process.env.NODE_ENV != 'production'),
            }
        },
        components: {
            LoggedInUserActions,
            ThemeSelector
        },
        computed: {
            currentUser(){
                return this.$store.getters.currentUser;
            },
            isLoggedInUserFeedMaintainer(){
                // TODO: Implement this and other permissions based on the returned roles
                return this.currentUser && this.currentUser.id;
            }
        }
    }
</script>

<style>
    
</style>