<template>   
    <div>       
        <div class="container-fluid">
            <div class="mt-2">
                <div>
                    <b-card no-body>
                        <b-tabs>
                            <b-tab title="Filter Search" active>
                                <b-card-text class="px-4 pb-4">
                                    <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">               
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="eventDate" class="">Start Date</label>
                                            <b-input-group class="mb-3">
                                                <input 
                                                    class="form-control" 
                                                    v-model="form.eventDateFormatted" type="text"
                                                    :disabled="showLoader"
                                                    autocomplete="off" id="eventDate" name="eventDate"/>
                                                <b-input-group-append>
                                                    <b-form-datepicker 
                                                        v-model="form.eventDate" 
                                                        v-validate="{required: true}" 
                                                        data-vv-name="eventDatePicker"
                                                        button-only right locale="en-AU" 
                                                        aria-controls="example-input"
                                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                                        @context="onDatePick">
                                                    </b-form-datepicker>
                                                </b-input-group-append>
                                            </b-input-group>  
                                            <div v-if="validated" class="text-danger">
                                                    {{errors.first('eventDatePicker')}}
                                            </div>                                       
                                        </div> 
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="daysAhead" class="">Days Ahead</label>
                                            <b-button-toolbar key-nav>
                                                <b-button-group>
                                                    <b-button style="margin-right: 1px" ref="d2" @click="setDaysAhead(2)" :selected="this.form.daysAhead === 2">2</b-button>
                                                    <b-button style="margin-right: 1px" ref="d4" @click="setDaysAhead(4)" :selected="this.form.daysAhead === 4">4</b-button>
                                                    <b-button ref="d6" @click="setDaysAhead(6)" :selected="this.form.daysAhead === 6">6</b-button>
                                                </b-button-group>
                                            </b-button-toolbar>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="filter" class=""><router-link :to="{ name: 'Filters' }">Filter</router-link></label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="form.selectedFilter"                                                    
                                                id="filter"
                                                @change="onCurrFilterChanged"
                                                :options="filters">
                                            </b-form-select>

                                            <span v-if="validated" class="text-danger">
                                                {{errors.first('filter')}}
                                            </span>                                       
                                        </div>                                           
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="orderBy" class="">Order By</label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="form.orderBy" 
                                                v-validate="{required: true}"
                                                data-vv-name="orderBy"                                        
                                                id="orderBy"
                                                :options="orderByReferences">
                                            </b-form-select>

                                            <span v-if="validated" class="text-danger">
                                                {{errors.first('orderBy')}}
                                            </span>                                       
                                        </div>                       
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="limit" class="">Limit</label>
                                            <b-form-input 
                                                id="limit" 
                                                v-model="form.limit" 
                                                type="number"
                                                step="1" min="10" max="250">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-3 col-lg-3 col-md-3" v-if="showFilterExplain">
                                            <label for="explain" class="">EventIds (explain)</label>
                                            <b-form-input 
                                                id="explain" 
                                                v-model="form.owcIds">
                                            </b-form-input>
                                            <b-tooltip target="explain" triggers="hover" placement="bottom" variant="primary" :delay="{ 'show': 500 }">
                                                Enter a semicolon (;) separated list of OWC event ids to check if they match the filter and what is the reason if they don't. Can be used for a single event as well.
                                            </b-tooltip>                                               
                                        </div>   
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="filterEvents" class="">&nbsp;</label>
                                            <button 
                                                type="button" 
                                                @click="onFilterEvents" 
                                                :disabled="showLoader"
                                                class="btn btn-primary align-self-end btn-block mt-2">Search</button>    
                                        </div> 
                                        <div class="col-xl-3 col-lg-1 col-md-1" v-if="!showFilterExplain"></div>
                                        <div class="col-xl-1 col-lg-2 col-md-2" v-if="!showFilterExplain">
                                            <label class="">&nbsp;</label>
                                            <button 
                                                type="button" 
                                                @click="showFilterExplain = true" 
                                                :disabled="showLoader"
                                                class="btn btn-secondary align-self-end btn-block mt-2">Explain</button>                            
                                        </div>                                                                                                                                                  
                                    </div>   
                                </b-card-text>
                            </b-tab>                            
                            <b-tab title="Detailed Search">
                                <b-card-text class="pl-4 pb-4">
                                    <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">               
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="eventDate" class="">Start Date</label>
                                            <b-input-group class="mb-3">
                                                <input 
                                                    class="form-control" 
                                                    v-model="form.eventDateFormatted" type="text"
                                                    :disabled="showLoader"
                                                    autocomplete="off" id="eventDate" name="eventDate"/>
                                                <b-input-group-append>
                                                    <b-form-datepicker 
                                                        v-model="form.eventDate" 
                                                        v-validate="{required: true}" 
                                                        data-vv-name="eventDatePicker"
                                                        button-only right locale="en-AU" 
                                                        aria-controls="example-input"
                                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                                        @context="onDatePick">
                                                    </b-form-datepicker>
                                                </b-input-group-append>
                                            </b-input-group>  
                                            <div v-if="validated" class="text-danger">
                                                    {{errors.first('eventDatePicker')}}
                                            </div>                                       
                                        </div> 
                                        <div class="col-xl-1 col-lg-1 col-md-1">
                                            <label for="daysAhead" class="">Days Ahead</label>
                                            <b-button-toolbar key-nav>
                                                <b-button-group>
                                                    <b-button style="margin-right: 1px" ref="d2" @click="setDaysAhead(2)" :selected="this.form.daysAhead === 2">2</b-button>
                                                    <b-button style="margin-right: 1px" ref="d4" @click="setDaysAhead(4)" :selected="this.form.daysAhead === 4">4</b-button>
                                                    <b-button ref="d6" @click="setDaysAhead(6)" :selected="this.form.daysAhead === 6">6</b-button>
                                                </b-button-group>
                                            </b-button-toolbar>
                                        </div>
                                        <div class="col-xl-1 col-lg-1 col-md-1">
                                            <label for="starMag" class="">Max Star Mag</label>
                                            <b-form-input 
                                                id="starMag" 
                                                v-model="form.starMag" 
                                                type="number"
                                                step="0.5" min="8" max="16">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="minDur" class="">Min Duration (sec)</label>
                                            <b-form-input 
                                                id="minDur" 
                                                v-model="form.minDur" 
                                                type="number"
                                                step="0.1" min="0.2" max="60">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-1 col-lg-1 col-md-1">
                                            <label for="magDrop" class="">Min Mag Drop</label>
                                            <b-form-input 
                                                id="magDrop" 
                                                v-model="form.magDrop" 
                                                type="number"
                                                step="0.1" min="0.1" max="10">
                                            </b-form-input>
                                        </div>   
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="aperture" class="">Aperture (cm)</label>
                                            <b-form-input 
                                                id="aperture" 
                                                v-model="form.aperture" 
                                                type="number"
                                                step="0.5" min="3" max="65">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="detectionFrames" class="">Detection (frames)</label>
                                            <b-form-input 
                                                id="detectionFrames" 
                                                v-model="form.detectionFrames" 
                                                type="number"
                                                step="1" min="1" max="10">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="magAdjust" class="">MagAdjust</label>
                                            <b-form-input 
                                                id="magAdjust" 
                                                v-model="form.magAdjust" 
                                                type="number"
                                                step="0.5" min="-5" max="5">
                                            </b-form-input>
                                        </div>                    
                                    </div>                                
                                    <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="location" class="">Location</label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="form.selectedSite" 
                                                v-validate="{required: true}"
                                                data-vv-name="location"                                        
                                                id="location"
                                                :options="locations">
                                            </b-form-select>

                                            <span v-if="validated" class="text-danger">
                                                {{errors.first('location')}}
                                            </span>                                       
                                        </div>                    
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="location" class="">Max Distance (km)</label>
                                            <b-form-input 
                                                id="distance" 
                                                v-model="form.distance" 
                                                type="number"
                                                step="1" min="1" max="1000">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="distFrom" class="">From</label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="form.distFrom" 
                                                v-validate="{required: true}"
                                                data-vv-name="distFrom"                                        
                                                id="distFrom"
                                                :options="distanceReferences">
                                            </b-form-select>

                                            <span v-if="validated" class="text-danger">
                                                {{errors.first('distFrom')}}
                                            </span>                                       
                                        </div>                       
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="orderBy" class="">Order By</label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="form.orderBy" 
                                                v-validate="{required: true}"
                                                data-vv-name="orderBy"                                        
                                                id="orderBy"
                                                :options="orderByReferences">
                                            </b-form-select>

                                            <span v-if="validated" class="text-danger">
                                                {{errors.first('orderBy')}}
                                            </span>                                       
                                        </div>                       
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="limit" class="">Limit (events)</label>
                                            <b-form-input 
                                                id="limit" 
                                                v-model="form.limit" 
                                                type="number"
                                                step="1" min="10" max="250">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="explain" class="">EventIds (explain)</label>
                                            <b-form-input 
                                                id="explain" 
                                                v-model="form.owcIds">
                                            </b-form-input>
                                        </div>                                                                               
                                    </div>   
                                    <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="minProb" class="">Min Prob. (%)</label>
                                            <b-form-input 
                                                id="minProb" 
                                                v-model="form.minProb" 
                                                type="number"
                                                step="5" min="0" max="100">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="maxEPW" class="">Max SigPW</label>
                                            <b-form-input 
                                                id="maxEPW" 
                                                v-model="form.maxEPW" 
                                                type="number"
                                                step="0.05" min="0.05" max="100">
                                            </b-form-input>
                                        </div>
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="maxRUWE" class="">Max RUWE</label>
                                            <b-form-input 
                                                id="maxRUWE" 
                                                v-model="form.maxRUWE" 
                                                type="number"
                                                step="0.05" min="0.05" max="100">
                                            </b-form-input>
                                        </div>          
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="altitude" class="">Min Altitude (deg)</label>
                                            <b-form-input 
                                                id="altitude" 
                                                v-model="form.minAltitude" 
                                                type="number"
                                                step="1" min="0" max="90">
                                            </b-form-input>
                                        </div>                                
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="inclDTO" class="">Daytime Events</label>
                                            <b-form-checkbox 
                                                id="inclDTO"
                                                v-model="form.inclDTO"
                                                class="cb-mid-align"
                                                > Include observable daytime events
                                            </b-form-checkbox>
                                        </div>            
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="maxSunAlt" class="">Max Sun Alt (deg)</label>
                                            <b-form-input 
                                                id="maxSunAlt" 
                                                v-model="form.maxSunAlt" 
                                                type="number"
                                                step="1" min="-18" max="0"
                                                :disabled="form.inclDTO"
                                                :readonly="form.inclDTO"
                                                :class="{ 'cb-grayout': form.inclDTO }">
                                            </b-form-input>
                                        </div>         
                                        <div class="col-xl-1 col-lg-1 col-md-1">
                                            <label for="flags" class="">Flags</label>
                                            <b-form-input 
                                                id="flags" 
                                                v-model="form.flags"
                                                title="d - Disable day-time check
                    a - Disable star altitude check
                    c - Disable combined magnitude/aperture/duration check
                    e - Include 'Explain' data for all included events
                    t - Only include tagged events
                    T - Exclude tagged events">
                                            </b-form-input>
                                        </div>                                                          
                                        <div class="col-xl-1 col-lg-2 col-md-2">
                                            <label for="searchEvents" class="">&nbsp;</label>
                                            <button 
                                                type="button" 
                                                @click="onSearchEvents" 
                                                :disabled="showLoader"
                                                class="btn btn-primary align-self-end btn-block mt-2">Search</button>    
                                        </div>                                       
                                    </div>  
                                </b-card-text>
                            </b-tab>
                            <b-tab title="Display Options">
                                <b-card-text class="pl-4 pb-4">
                                    <div class="row pt-2 mt-3 " v-bind:class="{ 'grayout': showLoader }">
                                        <div class="col-xl-2 col-lg-2 col-md-2">
                                            <label for="mapDisplay" class="">Map Display</label>
                                            <b-form-select 
                                                class="form-control" 
                                                v-model="displayOptions.mapDisplay" 
                                                id="mapDisplay"
                                                :options="mapDisplayOptions"
                                                @change="onMapDisplayOptionsChanged">
                                            </b-form-select>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-2" v-if="displayOptions.mapDisplay === 'zoomedMap'">
                                            <label for="autoZoom" class="">Map Settings</label>
                                            <b-form-checkbox 
                                                id="autoZoom"
                                                v-model="displayOptions.autoZoom"
                                                class="cb-mid-align"
                                                @change="onAutoZoomChanged"
                                                > Zoom &amp; center on selection
                                            </b-form-checkbox>
                                        </div>                                          
                                        <div class="col-xl-6 col-lg-6 col-md-6">
                                            <label for="showMinAper" class="">Optional Columns</label>
                                            <b-form-checkbox 
                                                id="forceShowMinAper"
                                                v-model="displayOptions.forceShowMinAper"
                                                class="cb-mid-align"
                                                @change="onForceShowMinAperChanged"
                                                > Show the minimum aperture (cm) requried to observe the event 
                                            </b-form-checkbox>
                                        </div>                                          
                                    </div>                                       
                                </b-card-text>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </div>                
               
                <div class="row pt-1 mt-1" v-if="submitted && submittedFilter">
                    <div class="col-12">
                        Search Results for filter
                        <span class="pl-1"><strong>{{ this.submittedFilter.name }}:</strong></span>
                        <span class="pl-2">{{ this.submittedFilter.distance }}km <span class="text-muted">from</span> {{ this.submittedFilter.distFrom }} <span class="text-muted">from</span> {{ this.submittedFilter.siteName }},</span>
                        <span class="pl-2"><span class="text-muted">StarMag:</span> {{ this.submittedFilter.starMag }}<sup>m</sup>, <span class="text-muted">MinDur:</span> {{ this.submittedFilter.minDur }}s, <span class="text-muted">Aperture:</span> {{ this.submittedFilter.aperture }}cm, <span class="text-muted">DetectionFrames:</span> {{ this.submittedFilter.detectionFrames}},</span>
                        <span class="pl-2" v-if="this.submittedFilter.maxEPWEnabled"><span class="text-muted">sigPW&lt; </span> {{ this.submittedFilter.maxEPW}},</span>
                        <span class="pl-2" v-if="!this.submittedFilter.maxEPWEnabled"><span class="text-muted">Probability&gt; </span> {{ this.submittedFilter.minProb}},</span>
                        <span class="pl-2"><span class="text-muted">MinStarAltitude: </span> {{ this.submittedFilter.minAltitude}}</span>
                    </div>
                </div>

                <div class="row pt-2 mt-3" v-if="submitted">
                    <div class="col-8">
                        <div class="table-responsive-md" v-if="(submitted || foundEventsList) && !showLoader">
                            <table class="table table-dark table-bordered table-hover">
                                <thead class="thead-dark text-center">
                                    <tr>
                                        <td>Asteroid</td>
                                        <td>Local Event Time, UT</td>                                
                                        <td>Mag</td>
                                        <td>Mag Drop</td>
                                        <td>Max Dur.</td>
                                        <td>Prob.</td>
                                        <td>SigPW</td>
                                        <td>Altitude</td>
                                        <td>Travel Dist.</td>
                                        <td v-if="showMagAdjust">MagAdjDiff</td>
                                        <td v-if="showMinAper">Min Aper.</td>
                                    </tr>    
                                </thead>
                                <tbody>
                                    <tr style="cursor: pointer;" v-for="evt in foundEventsList" :key="evt.id"  @click="selectEvent(evt)" :class="selectedEvent && evt.id === selectedEvent.id ? 'highlight' : ''">
                                        <td><OwPositionBox :pos="evt.pos" class="pr-2"/> {{evt.asteroid}}</td>
                                        <td>{{evt.locTime}} <TagList v-if="evt.tags" :tags="evt.tags" /></td>
                                        <td class="text-center">{{evt.mag | formatMag}}</td>
                                        <td class="text-center">{{evt.magDrop | formatMag}}</td>
                                        <td class="text-center">{{evt.maxDur | formatMag}}</td>
                                        <td class="text-center">{{evt.prob * 100 | formatFixed0}} %</td>
                                        <td class="text-center">{{evt.sigPW | formatFixed2}}</td>
                                        <td class="text-center">{{evt.alt | formatFixed0}}° {{ evt.az | formatAzDir}}</td>
                                        <td class="text-center">{{Math.abs(evt.travelDist) | formatFixed0}} km</td>
                                        <td class="text-center" v-if="showMagAdjust"><span v-if="evt.mgaDiff > 0">{{evt.mgaDiff | formatFixed1}}</span></td>
                                        <td class="text-center" v-if="showMinAper"><span v-if="evt.minAper > 0">{{evt.minAper | formatFixed0}} cm</span></td>
                                    </tr>    
                                </tbody> 
                            </table>
                            <div class="text-muted pt-2" v-if="submitted && foundEventsList"><small>Found <strong class="text-light">{{foundEventsList.length}}</strong> event{{foundEventsList.length > 1 ? 's' : ''}} in <strong class="text-light">{{msElapsed / 1000}}</strong> sec</small></div>
                        </div>     
                        <div class="table-responsive-md" v-if="selectedEvent && selectedEvent.expl && form.owcIds && selectedEvent.expl.find(x => x.Pass == false)">
                            <table class="table table-dark table-bordered table-hover">
                                <thead class="thead-dark text-center">
                                    <tr>
                                        <td colspan="3"><span class="fail">Explanation - Fail</span></td>
                                    </tr>    
                                </thead>                                        
                                <tbody>
                                    <tr style="cursor: pointer;" v-for="(expl, i) in selectedEvent.expl" :key="i" :class="expl.Pass ? '' : 'fail'">
                                        <td class="text-center">{{expl.Pass ? 'Pass' : 'Fail'}}</td>
                                        <td>{{expl.Name}}</td>
                                        <td>{{expl.Description}}</td>
                                    </tr>    
                                </tbody> 
                            </table>
                        </div>
                        <div class="table-responsive-md" v-if="(submitted || foundEventsList) && !showLoader && foundEventsExecInfo">
                            <div class="row">
                                <hr size="1"/>
                                <div class="col-lg-12 pt-2 ">
                                    Search Performance Data
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <div class="text-muted"><small>Pre-handle time: <strong class="text-light">{{foundEventsExecInfo.PreHandleTime | formatFixed0}} ms</strong></small></div>
                                    <div class="text-muted"><small>Pre-search time: <strong class="text-light">{{foundEventsExecInfo.PreSearchTime | formatFixed0}} ms</strong></small></div>
                                    <div class="text-muted"><small>Total exec time: <strong class="text-light">{{foundEventsExecInfo.TotalExecTime | formatFixed0}} ms</strong></small></div>
                                </div>
                                <div class="col-2">
                                    <div class="text-muted"><small>Total DB time: <strong class="text-light">{{foundEventsExecInfo.TotalDbTime | formatFixed0}} ms</strong></small></div>
                                    <div class="text-muted"><small>Total filter time: <strong class="text-light">{{foundEventsExecInfo.TotalFilterTime | formatFixed0}} ms</strong></small></div>
                                    <div class="text-muted"><small>Total event calc time: <strong class="text-light">{{foundEventsExecInfo.TotalEventCalcTime | formatFixed0}} ms</strong></small></div>
                                </div>
                                <div class="col-2">
                                    <div class="text-muted"><small>Events Checked: <strong class="text-light">{{foundEventsExecInfo.EventsChecked}}</strong></small></div>
                                    <div class="text-muted"><small>Events Matched: <strong class="text-light">{{foundEventsExecInfo.EventsMatched}}</strong></small></div>
                                    <div class="text-muted"><small>Excluded [Dec]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedDec}}</strong></small></div>
                                </div>
                                <div class="col-2">
                                    <div class="text-muted"><small>Excluded [Day Time]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedDayTime}}</strong></small></div>
                                    <div class="text-muted"><small>Excluded [Alt]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedAlt}}</strong></small></div>
                                    <div class="text-muted"><small>Excluded [Aper/Mag/Dur]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedMagDurDrop}}</strong></small></div>
                                </div>
                                <div class="col-2">
                                    <div class="text-muted"><small>Excluded [Probability]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedProbability}}</strong></small></div>
                                    <div class="text-muted"><small>Excluded [TaggedFilter]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedTagFilter}}</strong></small></div>
                                    <div class="text-muted"><small>Total load tagged time: <strong class="text-light">{{foundEventsExecInfo.TotalTaggedPredLoadTime | formatFixed0}} ms</strong></small></div>
                                </div>
                                <div class="col-2">
                                    <div class="text-muted"><small>Excluded [FastDistCheck]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedFastDistCheckFilter}}</strong></small></div>
                                    <div class="text-muted"><small>Excluded [SigPW]: <strong class="text-light">{{foundEventsExecInfo.EventsExcludedSigPWFilter}}</strong></small></div>
                                </div>                                
                            </div>
                        </div>                        
                        <div class="justify-content-center text-center">
                            <b-spinner v-if="showLoader" class="justify-content-center mt-3" varient="secondary" style="width: 3rem; height: 3rem;" ></b-spinner>
                            <div v-if="showLoader && form.daysAhead < 5" class="text-muted pt-4">This search may take several seconds...</div>
                            <div v-if="showLoader && form.daysAhead > 5" class="text-muted pt-4">This search may take a few minutes...</div>
                            <h5 class="text-danger" v-if="foundEventsList && foundEventsList.length < 1 && submitted && !showLoader">No events found</h5>
                        </div>
                    </div>     
                    <div class="col-md-4 col-12">
                        <div class="sticky">
                            <EarthPlot ref="earthPlot" v-if="displayOptions.mapDisplay != 'zoomedMap' &&  foundEventsList"/>
                            <ZoomedGoogleMap ref="zoomedMap" :location=this.form.selectedSite v-if="displayOptions.mapDisplay === 'zoomedMap' && foundEventsList"/>
                            <div class="row" v-if="selectedEvent">
                                <div class="col-lg-12 text-center pt-2 pass">
                                    <h6><a href="#" @click="openSelectedEvent()">{{selectedEvent.asteroid}} occults {{selectedEvent.star}} on {{selectedEvent.time}}</a></h6>
                                </div>                     
                            </div>
                            <div class="row" v-if="selectedEvent">
                                <div class="col-12 pt-2">
                                    <OccultWatcherPlot ref="owPlot" :occel="selectedEvent.data" :preview="'on'"/> 
                                </div>
                                <div class="col-4 pt-2">
                                    <button 
                                        type="button" 
                                        @click="onAddStation" 
                                        :disabled="showLoader"
                                        class="btn btn-success align-self-end btn-block mt-2">Add Station</button>
                                </div>
                            </div>  
                            <div class="row" v-if="selectedEvent && selectedEvent.expl && !selectedEvent.expl.find(x => x.Pass == false)">
                                <div class="col-12 pt-2">
                                    <table class="table table-dark table-bordered table-hover">
                                        <thead class="thead-dark text-center">
                                            <tr>
                                                <td colspan="3"><span class="pass">Explanation - Pass</span></td>
                                            </tr>    
                                        </thead>                                        
                                        <tbody>
                                            <tr style="cursor: pointer;" v-for="(expl, i) in selectedEvent.expl" :key="i">
                                                <td class="text-center">{{expl.Pass ? 'Pass' : 'Fail'}}</td>
                                                <td>{{expl.Name}}</td>
                                                <td>{{expl.Description}}</td>
                                            </tr>    
                                        </tbody> 
                                    </table>
                                </div>
                            </div> 
                        </div>
                    </div>                                    
                </div>          
            </div> 
        </div>             
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import OwPositionBox from '../components/OwPositionBox.vue';
import EarthPlot from '@/components/EarthPlot.vue'
import ZoomedGoogleMap from '@/components/ZoomedGoogleMap.vue'
import OccultWatcherPlot from '@/components/OccultWatcherPlot.vue'
import TagList from '@/components/TagList.vue'

export default {
    data() {
        return {
            form: {
                eventDate: '',
                eventDateFormatted: '',
                daysAhead: 2,
                selectedSite: null,
                selectedFilter: null,
                distance: 100,
                distFrom: "center",
                detectionFrames: 4,
                aperture: 20,
                magAdjust: 0,
                minProb: 0,
                maxEPW: 100,
                maxRUWE: 100,
                maxSunAlt: -9,
                inclDTO: false,
                limit: 50,
                starMag: 16.0,
                minDur: 0.4,
                magDrop: 0.1,
                minAltitude: 15,
                orderBy: "mag",
                owcIds: null,
                flags: null
            },
            showLoader: false,
            showMinAper: false,
            showMagAdjust: false,
            showFilterExplain: false,
            validated: false,
            submitted: false,
            submittedFilter: null,
            selectedEvent: null,
            msElapsed: 0,
            displayOptions: {
                forceShowMinAper: false,
                mapDisplay: "zoomedMap",
                autoZoom: true
            },
            distanceReferences: [
                {"value": "center", "text": "Center"},
                {"value": "shadow", "text": "Shadow Edge"},
                {"value": "sigma", "text": "1-Sigma Edge"}
            ],
            orderByReferences: [
                {"value": "dur", "text": "Duration"},
                {"value": "mag", "text": "Star Mag"},
                {"value": "rank", "text": "Rank"},
                {"value": "time", "text": "Time"},
            ],
            mapDisplayOptions: [
                {"value": "globe", "text": "Globe"},
                {"value": "zoomedMap", "text": "Zoomed Map"}
            ]
        }
    },
    components: {
        OwPositionBox,
        EarthPlot,
        ZoomedGoogleMap,
        OccultWatcherPlot,
        TagList
    },     
    methods: {
        ...mapActions(['loadUserSites', 'loadUserFilters', 'filterEvents', 'filterEvents2']),
        onDatePick(ctx) {
            this.form.eventDateFormatted = ctx.selectedFormatted;
        },        
        setDaysAhead(days){
            this.form.daysAhead = days;
        },
        async selectSite(site) {
            this.form.selectedSite = site;
            this.$refs.zoomedMap?.updateLocation(this.form.selectedSite);
        },
        getFormattedDate(yyyyMMdd) {
            let toks = yyyyMMdd.split('-');
            let mm = parseInt(toks[1]);
            let mmm = "JanFebMarAprMayJunJulAugSepOctNovDec".substring(mm - 1);
            return toks[2] + ' ' + mmm + ' ' + toks[0];

        },
        async onSearchEvents(){
            this.validated = true;
            var result = await this.$validator.validate();

            if (result)
            {
                this.showLoader = true;
                this.showMinAper = false;
                this.showMagAdjust = false;

                if (this.selectedEvent)
                {
                    this.selectedEvent = null;
                    this.$refs.earthPlot?.clear();
                }

                try 
                {
                    const now = new Date;
                    const utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

                    let query = {
                        "lng": this.form.selectedSite.Lng,
                        "lat": this.form.selectedSite.Lat,
                        "alt": this.form.selectedSite.Alt,
                        "siteName": this.form.selectedSite.Name,
                        "date": this.form.eventDate,
                        "days": this.form.daysAhead,
                        "starMag": this.form.starMag,
                        "minDur": this.form.minDur,
                        "magDrop": this.form.magDrop,
                        "aperture": this.form.aperture,
                        "detectionFrames": this.form.detectionFrames,
                        "magAdjust": this.form.magAdjust,
                        "prob": this.form.minProb / 100.0,
                        "maxEPW": this.form.maxEPW,
                        "maxRUWE": this.form.maxRUWE,
                        "maxSunAlt" : this.form.maxSunAlt,
                        "inclDTO" : this.form.inclDTO,
                        "minExp": this.form.minExposure,
                        "maxExp": this.form.maxExposure,
                        "dist": this.form.distance,
                        "distFrom": this.form.distFrom,
                        "altitude": this.form.minAltitude,
                        "limit": this.form.limit,
                        "orderBy": this.form.orderBy,
                        "owcIds": this.form.owcIds,
                        "flags": this.form.flags,
                        "explain": this.form.flags?.indexOf('e') > -1,
                        "timestamp": utc_timestamp,
                        "options": "fPlane"
                    };
                    
                    localStorage.setItem('owc-event-search-filter', JSON.stringify(query));

                    this.submitted = true;
                    this.showMagAdjust = this.form.magAdjust && this.form.magAdjust > 0;
                    this.showMinAper = !this.showMagAdjust;

                    const startTime = Date.now();
                    await this.filterEvents(query);
                    this.msElapsed = Date.now() - startTime;

                    this.$refs.zoomedMap?.updateLocation(this.form.selectedSite);

                    if (this.foundEventsList && this.foundEventsList.length > 0) {
                        this.selectEvent(this.foundEventsList[0]);
                        setTimeout(() => {this.selectEvent(this.foundEventsList[0]);}, 200);   
                    }
                }
                catch(error)
                {
                    this.showToast(error.message, 'ERROR');                    
                }
                finally
                {
                    this.showLoader = false;
                }

                this.validated = false;
            }
        },
        async onFilterEvents(){

            if (!this.form.selectedFilter)
            {
               this.errors.add({field: 'filter', msg: 'The filter field is required.'});
               this.validated = true;
               return 
            }

            this.showMinAper = false;
            this.showMagAdjust = false;
            this.showLoader = true;
            this.submittedFilter = null;

            if (this.selectedEvent)
            {
                this.selectedEvent = null;
                this.$refs.earthPlot?.clear();
            }

            try 
            {
                const now = new Date;
                const utc_timestamp = Date.UTC(now.getUTCFullYear(),now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

                if (!this.form.limit) this.form.limit = 50;

                let query = {
                    "date": this.form.eventDate,
                    "days": this.form.daysAhead,
                    "filterId": this.form.selectedFilter.id,
                    "limit": this.form.limit,
                    "orderBy": this.form.orderBy,
                    "owcIds": this.form.owcIds,
                    "timestamp": utc_timestamp,
                    "options": "fPlane"
                };
                
                var cachedFilter = localStorage.getItem('owc-event-search-filter');
                if (cachedFilter)
                {
                    let savedFilter = JSON.parse(cachedFilter);
                    savedFilter.date = query.date;
                    savedFilter.days = query.days;
                    savedFilter.orderBy = query.orderBy;
                    savedFilter.limit = query.limit;
                    savedFilter.owcIds = query.owcIds;

                    localStorage.setItem('owc-event-search-filter', JSON.stringify(savedFilter));
                }

                localStorage.setItem('owc-selected-user-filter-name', this.form.selectedFilter.name);
                this.submitted = true;
                this.showMinAper = this.displayOptions.forceShowMinAper;

                const startTime = Date.now();
                await this.filterEvents2(query);
                this.msElapsed = Date.now() - startTime;

                this.submittedFilter = this.form.selectedFilter;
                
                this.$refs.zoomedMap?.updateLocation(this.form.selectedSite);

                if (this.foundEventsList && this.foundEventsList.length > 0) {
                    this.selectEvent(this.foundEventsList[0]);
                    setTimeout(() => {this.selectEvent(this.foundEventsList[0]);}, 200);   
                }
            }
            catch(error)
            {
                this.showToast(error.message, 'ERROR');                    
            }
            finally
            {
                this.showLoader = false;
                this.validated = false;
            }
        },
        async selectEvent(evt) {
            this.selectedEvent = evt;
            this.plotSelectedEvent();
        },
        async plotSelectedEvent() {
             if (this.selectedEvent)
             {
                this.$refs.earthPlot?.plotEvent(this.selectedEvent.data);
                var stations = [{ id: 0, descr: this.form.selectedSite.Name, dist: this.selectedEvent.dist }];
                this.$refs.owPlot?.plot(this.selectedEvent.data, stations);
                this.$refs.zoomedMap?.plotEvent(this.selectedEvent.fPlane, this.selectedEvent.midTime, this.selectedEvent.travelDist, this.displayOptions.autoZoom);
             }
        },        
        selectDefaultFilter(){
            if (this.filters != null && this.filters.length > 0) 
            {
                var selectedFilter = this.filters[0];
                var prevSelectedFilterName = localStorage.getItem('owc-selected-user-filter-name');
                if (prevSelectedFilterName)
                {
                    var prevSelectedFilter = this.filters.filter(x => x.text == prevSelectedFilterName);
                    if (prevSelectedFilter && prevSelectedFilter.length == 1) selectedFilter = prevSelectedFilter[0];
                }
                if (selectedFilter)
                {
                    this.form.selectedFilter = selectedFilter.value;
                    this.onCurrFilterChanged();
                }
            }
        },        
        openSelectedEvent() {
            this.openEvent(this.selectedEvent);
        },
        openEvent(evt) {
            if (evt)
            {
                let routeData = this.$router.resolve({ name: 'Event', params: { eventId: this.selectedEvent.id } });
                window.open(routeData.href, '_blank');
            }
        },
        onKeyDown(event){
            if (this.selectedEvent && this.foundEventsList)
            {
                if (event.key == 'ArrowDown' || event.key == 'ArrowUp')
                {
                    var idx = this.foundEventsList.indexOf(this.selectedEvent);

                    if (event.key == 'ArrowDown' && idx < this.foundEventsList.length - 1)
                        this.selectEvent(this.foundEventsList[idx + 1]);
                    else if (event.key == 'ArrowUp' && idx > 0)
                        this.selectEvent(this.foundEventsList[idx - 1]);
                    
                    event.stopPropagation();
                    event.preventDefault();
                }
            }
        },
        onCurrFilterChanged(){
            if (this.form.selectedFilter && this.currentUserSites)
            {
                var siteForFilter = this.currentUserSites.filter(x => x.Name == this.form.selectedFilter.siteName);
                if (siteForFilter && siteForFilter.length === 1)
                {
                    this.selectSite(siteForFilter[0]);
                }
            }
        },
        loadDisplayOptions(){
            var savedDisplayOptions = localStorage.getItem('owc-event-search-displayOptions');
            if (savedDisplayOptions)
            {
                let saved = JSON.parse(savedDisplayOptions);
                this.displayOptions.forceShowMinAper = saved.forceShowMinAper;
                if (saved.mapDisplay == "zoomedMap")
                    this.displayOptions.mapDisplay = "zoomedMap"
                else if (saved.mapDisplay == "globe")
                    this.displayOptions.mapDisplay = "globe"      
                this.displayOptions.autoZoom = saved.autoZoom ?? true;         
            }
        },
        onForceShowMinAperChanged(newForceShowValue){
            this.displayOptions.forceShowMinAper = newForceShowValue;
            this.showMinAper = newForceShowValue;
            localStorage.setItem('owc-event-search-displayOptions', JSON.stringify(this.displayOptions));
        },
        onMapDisplayOptionsChanged(newMapShowValue){
            this.displayOptions.mapDisplay = newMapShowValue;
            localStorage.setItem('owc-event-search-displayOptions', JSON.stringify(this.displayOptions));
            this.plotSelectedEvent();
        },
        onAutoZoomChanged(newAutoZoomValue){
            this.displayOptions.autoZoom = newAutoZoomValue;
            localStorage.setItem('owc-event-search-displayOptions', JSON.stringify(this.displayOptions));
            this.plotSelectedEvent();
        },
        onAddStation() {
            let routeData = this.$router.resolve({ name: 'Event', params: { eventId: this.selectedEvent.id } });
            var href = routeData.href + `?action=editStations&predictionId=${this.selectedEvent.defPredId ?? 'Horizons;GaiaDR3'}&lat=${this.form.selectedSite.Lat}&lng=${this.form.selectedSite.Lng}`
            console.log(`Opening up event to add a station: ${href}`)
            window.open(href, '_blank');
        }
    },
    async mounted() {
        this.showLoader = true;

        try 
        {
            await this.loadUserSites();
            await this.loadUserFilters();

            this.loadDisplayOptions();

            var cachedFilter = localStorage.getItem('owc-event-search-filter');
            if (cachedFilter)
            {
                let savedFilter = JSON.parse(cachedFilter);
                this.form.daysAhead = savedFilter.days;
                this.form.starMag = savedFilter.starMag;
                this.form.minDur = savedFilter.minDur;
                this.form.magDrop = savedFilter.magDrop;
                this.form.aperture = savedFilter.aperture;
                this.form.detectionFrames = savedFilter.detectionFrames;
                this.form.magAdjust = savedFilter.magAdjust;
                this.form.maxEPW = savedFilter.maxEPW ?? 100;
                this.form.maxRUWE = savedFilter.maxRUWE ?? 100;
                this.form.maxSunAlt = savedFilter.maxSunAlt ?? -9,
                this.form.inclDTO = savedFilter.inclDTO ?? false,
                this.form.minProb = Math.trunc(savedFilter.prob * 100.0) ?? 0;
                this.form.minExposure = savedFilter.minExp;
                this.form.maxExposure = savedFilter.maxExp;
                this.form.distance = savedFilter.dist;
                this.form.distFrom = savedFilter.distFrom;
                this.form.minAltitude = savedFilter.altitude;
                this.form.limit = savedFilter.limit;
                this.form.orderBy = savedFilter.orderBy ?? "mag";
                this.form.owcIds = savedFilter.owcIds;
                this.form.flags = savedFilter.flags;

                if (savedFilter.siteName && this.currentUserSites && this.currentUserSites.length > 0)
                {
                    var siteRes = this.currentUserSites.filter(x => x.Name == savedFilter.siteName);
                    if (siteRes && siteRes.length == 1)
                        this.selectSite(siteRes[0]);
                }

                if (savedFilter.date)
                {
                    this.form.eventDate = savedFilter.date;
                    this.form.eventDateFormatted = this.getFormattedDate(savedFilter.date);
                }
                
                if (this.form.owcIds) this.showFilterExplain = true;
            }

            if (!this.form.eventDate)
            {
                // Default initial search date to Today
                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                var yyyy = today.getFullYear();

                this.form.eventDate = yyyy + '-' + mm + '-' + dd ;
                this.form.eventDateFormatted = this.getFormattedDate(this.form.eventDate);
            }

            this.selectDefaultFilter();

            if (this.currentUserSites && this.currentUserSites.length > 0 && this.form.selectedSite == null) 
            {
                this.selectSite(this.currentUserSites[0]);
            }

            document.addEventListener('keydown', this.onKeyDown);

            this.showLoader = false;
        }
        catch(error)
        {
            this.showLoader = false;
            this.showToast(error.message, 'ERROR');                    
        }
    },
    destroyed(){
        document.removeEventListener("keydown", this.onKeyDown); 
    },
    computed: {
        currentUserSites() {
            return this.$store.getters.currentUserSites;
        },
        currentUserFilters(){
            return this.$store.getters.currentUserFilters;
        },
        foundEventsList() {
            return this.$store.getters.filterEventSearchResult;
        },
        foundEventsExecInfo() {
            return this.$store.getters.filterEventSearchExecInfo;
        },
        locations() {
            return this.currentUserSites.map(x => ({ "value": x, "text": x.Name}));
        },
        filters(){
            return this.currentUserFilters.map(x => ({ "value": x, "text": x.name}));
        }
    }
}
</script>

<style scoped>
 .btn-group button[selected='selected']
 {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
 }

.btn-group button:focus {
    border-color: #80a8ff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(100, 167, 255, 0.5);
}

.non-tab-top-align {
    padding-top: 14px;
}

.pass {
    color:rgb(0, 188, 140)
}

.fail {
    color:rgb(224, 88, 88)
}

.cb-mid-align {
    padding-top: 6px;
}

.cb-grayout {
    background-color: rgb(191, 191, 191);
}

div.sticky {
    position: sticky;
    top: 0;      
}
</style>